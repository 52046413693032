import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaGoogle } from "react-icons/fa"; // Import icons for Google
import Footer from "../Navbaar&Footer/Footer";
import Navbar from "../Navbaar&Footer/Navbar";


const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://procydia.onrender.com/api/vendor/login",
        { email, password }
      );
  
      console.log('Login Response:', response.data); // Add this line to log the response
  
      const { token } = response.data;
      localStorage.setItem("authToken", token);
  
      const profileResponse = await axios.get(
        "https://procydia.onrender.com/api/vendor/profile",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
  
      console.log('Profile Response:', profileResponse.data); // Add this line to log the profile response
  
      const vendorId = profileResponse.data._id;
      navigate(`/editprofile/${vendorId}`);
    } catch (error) {
      console.error('Login Error:', error); // Log the error for debugging
      alert("Incorrect Email or Password!");
    }
  };
  

  const handleGoogleSignIn = () => {
    window.location.href = "https://procydia.onrender.com/api/auth/google";
  };

  return (
    <>
      <Navbar />
      <div
        className="flex items-center justify-center min-h-screen bg-cover bg-center p-8"
        style={{ backgroundImage: `url('register-bg.jpg')`}} // Custom background
      >
        <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-lg">
          <h1 className="text-3xl font-bold mb-6 text-center">Welcome 🤗!</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                className="mt-1 block w-full p-3 border border-gray-300 rounded-md"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                className="mt-1 block w-full p-3 border border-gray-300 rounded-md"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="bg-red-500 text-white hover:bg-red-600 w-full p-3 rounded-md"
            >
              Login
            </button>
          </form>
          {/* <p className="text-center p-5 pb-0">OR</p>

          <div className="mt-6">
            <button
              onClick={handleGoogleSignIn}
              className="flex items-center justify-center bg-blue-500 text-white hover:bg-blue-600 w-full p-3 rounded-md mb-4"
            >
              <FaGoogle className="mr-2" /> Sign in with Google
            </button>
          </div> */}

          <p className="mt-4 text-sm text-gray-600 text-center">
            Don't have an account?{" "}
            <a href="/contact" className="text-red-500">
            ,register as vendor now!
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LoginPage;
