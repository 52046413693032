import React from "react";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";

const Careers = () => {
  const jobList = [
    {
      id: 1,
      title: "Content Writer",
      location: "Delhi,India",
      url: "https://forms.gle/jjsuzFKkwQgaRRdU7",
      description:
        "Join our dynamic team.",
    },
  ];

  return (
    <>
      <Navbar />
      <div className="bg-gray-100 min-h-screen m-10 p-6 sm:p-12">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
            Join Our Team
          </h1>
          <p className="text-lg text-center text-gray-600 mb-12">
            We are always on the lookout for talented individuals to join our growing team. Check out our current job openings below and apply today!
          </p>

          <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {jobList.map((job) => (
              <div key={job.id} className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
                <h2 className="text-2xl font-semibold text-gray-800">{job.title}</h2>
                <p className="text-sm text-gray-500 mb-4">{job.location}</p>
                <p className="text-gray-600 mb-6">{job.description}</p>
                <a
                  href={job.url}
                  className="inline-block bg-blue-600 text-white py-2 px-4 rounded-md text-center w-full font-medium hover:bg-blue-700 transition duration-300"
                >
                  Apply Now
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Careers;
