import React from "react";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";

const WeddingCatalogue = () => {
  const categories = [
    {
      name: "Venue",
      image:
        "https://img.freepik.com/free-photo/location-symbol-with-landscape-background_23-2149906273.jpg?t=st=1723547031~exp=1723550631~hmac=2ae6900704108a275e72a0d66451d6eb84495021d326de5402847610cbd0d8a3&w=1380",
      url: "/location",
    },
    {
      name: "Catering",
      image:
        "https://img.freepik.com/free-photo/top-view-circular-food-frame_23-2148723455.jpg?t=st=1723547067~exp=1723550667~hmac=945ed0a419021dcd1a06e3cde545d2096660987b1326006d4b08ed8348acc498&w=1380",
      url: "/location",
    },
    {
      name: "Decoration",
      image:
        "https://img.freepik.com/free-photo/friends-exchanging-present-christmas-dinner_23-2147725422.jpg?t=st=1723547457~exp=1723551057~hmac=30d4a9c77d907c66cd424aac57093687febed719cf3f2d3e07f3e3f5a4fb20fe&w=1380",
      url: "/location",
    },
    {
      name: "Music",
      image:
        "https://img.freepik.com/free-photo/smiley-female-dj-mixing-club_23-2148325472.jpg?t=st=1723547508~exp=1723551108~hmac=8e78c40745a8128948b37bc8446bec7cf11aaa276bb26f45893df9d3e09ba739&w=1380",
      url: "/location",
    },
    {
      name: "Fashion Designers",
      image:
        "https://img.freepik.com/free-photo/young-indian-woman-wearing-sari_23-2149400848.jpg?t=st=1723547786~exp=1723551386~hmac=c502f4440a01d23d7e21b736dbb8979daa6b6b4c1bea21d8cf3213cea89d6011&w=1380",
      url: "/location",
    },
    {
      name: "Photography",
      image:
        "https://img.freepik.com/free-photo/wedding-card-engagement-ring_23-2148468536.jpg?t=st=1723547820~exp=1723551420~hmac=4e6602dc9f38bd6ee6c35ddcf165dc2d247056c5c995d98d4e57206a5a4f7589&w=1060",
      url: "/location",
    },
    {
      name: "choreography",
      image:
        "https://img.freepik.com/free-photo/dynamic-portrait-young-man-woman-dancing-hiphop-isolated-black-background-with-mixed-lights-effect_155003-46269.jpg?t=st=1723547949~exp=1723551549~hmac=4152751e4db5fdecdec0f2af10c48df7b0461e789f103354fd41271359277ae7&w=1380",
      url: "/location",
    },
    {
      name: "Car Reantals",
      image:
        "https://img.freepik.com/free-photo/funny-license-plate-vehicle_23-2150165777.jpg?t=st=1723548046~exp=1723551646~hmac=4919dfb01d1c60bcd760d642b8de436da270d7a5b418c98710c2d84d6bfd8f97&w=1380",
      url: "/location",
    },
  ];

  return (
    <div className="p-0 m-0">
      <Navbar />
      <div className="bg-gray-200 m-20 p-20 mt-36 rounded-2xl">
        <h1 className="text-3xl font-bold text-center mb-8">
          Explore All Categories..
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {categories.map((category, index) => (
            <a
              key={index}
              href={category.url}
              className="relative block bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
            >
              <img
                src={category.image}
                alt={category.name}
                className="object-cover w-full h-48 sm:h-56 md:h-64 border-x-4 border-y-4 border-red-400"
                onError={(e) =>
                  (e.target.src =
                    "https://via.placeholder.com/400x300?text=Image+Not+Found")
                }
              />
              <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
                <h2 className="text-white text-xl font-semibold">
                  {category.name}
                </h2>
              </div>
            </a>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WeddingCatalogue;
