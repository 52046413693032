import React from "react";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";

const TermsAndConditions = () => {
    return (
        <div>
            <Navbar />
            <div className="p-10 m-10"><h1 >T & C</h1></div>
            <Footer />
        </div>
    )
}


export default TermsAndConditions;