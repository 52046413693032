import React, { useEffect, useRef, useState, useNavigate } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";
import { FaEdit } from "react-icons/fa";
import ProductComponent from "./Products";
import ServiceComponent from "../Home/services";
import ReviewsComponent from "./Reviews";
import ChatBubble from "./ChatBubble";
import { BsInstagram } from "react-icons/bs";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaPhoneVolume } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";
import { IoEarth } from "react-icons/io5";
import { LuFolderEdit } from "react-icons/lu";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import VendorServices from "../Home/services";
import { FaInstagram, FaLinkedin, FaFacebook, FaYoutube } from 'react-icons/fa';

// Mapping icons to social media names
const socialIcons = {
  instagram: <FaInstagram />,
  linkedin: <FaLinkedin />,
  facebook: <FaFacebook />,
  youtube: <FaYoutube />,
};

// Define hover colors for each social platform
const hoverColors = {
  instagram: 'hover:text-pink-500',
  linkedin: 'hover:text-blue-500',
  facebook: 'hover:text-blue-800',
  youtube: 'hover:text-red-500',
};


  // Helper function to render stars based on rating
  const renderStars = (rating) => {
    return Array.from({ length: 5 }, (_, index) => (
      <span key={index} className={`text-xl ${index < rating ? 'text-yellow-400' : 'text-gray-300'}`}>
        ★
      </span>
    ));
  };


const VendorDetails = ({}) => {
  const [reviews,setReviews] = useState([]);
  const [images, setImages] = useState([]);
  const { vendorId } = useParams();
  const [services, setServices] = useState([]);
  const [vendor, setVendor] = useState({
    companyName: "",
    ownerName: "",
    profilepicture: "",
    backgroundpicture: "",
    address: "",
    phone: "",
    instagram: "",
    facebook: "",
    linkedIn: "",
    email: "",
    webiste: "",
    services: {},
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [editingField, setEditingField] = useState(null);
  const [uploadField, setUploadField] = useState(null); // Track which field (profile or background) to upload
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchVendorDetails = async () => {
      try {
        const response = await axios.get(
          `https://procydia.onrender.com/api/vendor/${vendorId}`
        );
        setVendor(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVendorDetails();
  }, [vendorId]);

  // const handleImageChange = (e) => {
  //   setSelectedFile(e.target.files[0]);
  // };

  // const handleUpload = async () => {
  //   if (!selectedFile) return;

  //   const formData = new FormData();
  //   formData.append("profilepicture", selectedFile); // The name here should match the backend

  //   try {
  //     await axios.post(
  //       `https://procydia.onrender.com/api/vendor/upload/${vendorId}`,
  //       formData
  //     );
  //     // Refresh vendor data
  //     const response = await axios.get(
  //       `https://procydia.onrender.com/api/vendor/${vendorId}`
  //     );
  //     setVendor(response.data);
  //     setSelectedFile(null);
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //   }
  // };

  // const triggerFileInput = (field) => {
  //   setUploadField(field); // Set the field before triggering file input
  //   fileInputRef.current.click();
  // };

  // useEffect(() => {
  //   if (selectedFile) {
  //     handleUpload();
  //   }
  // }, [selectedFile]);

  // const handleEditClick = (field) => {
  //   setEditingField(field);
  // };

  // const handleFieldChange = (field, value) => {
  //   setVendor((prevVendor) => ({
  //     ...prevVendor,
  //     [field]: value,
  //   }));
  // };

  // const handleFieldSubmit = async (field) => {
  //   try {
  //     const token = localStorage.getItem("authToken");

  //     if (!token) {
  //       throw new Error("No token found");
  //     }

  //     const response = await axios.put(
  //       `https://procydia.onrender.com/api/vendor/${vendorId}`,
  //       { [field]: vendor[field] },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     const updatedVendorResponse = await axios.get(
  //       `https://procydia.onrender.com/api/vendor/${vendorId}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     setVendor(updatedVendorResponse.data);
  //     setEditingField(null);
  //   } catch (error) {
  //     console.error(
  //       "Error updating field:",
  //       error.response ? error.response.data : error.message
  //     );
  //     setError("Error updating field. Please try again.");
  //   }
  // };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

    // Construct social links based on available vendor data
    const socialMediaLinks = [
      { name: 'instagram', link: vendor.instagram },
      { name: 'linkedin', link: vendor.linkedin },
      { name: 'facebook', link: vendor.facebook },
      { name: 'youtube', link: vendor.youtube },
    ].filter((social) => social.link); // Filter out empty links

  return (
    <div>
      <Navbar />
      <div
  className="relative mt-20 w-fill h-96 p-9 shadow-md bg-center"
  style={{
    backgroundImage: `url(${
      vendor.backgroundpicture ||
      "https://img.freepik.com/free-vector/happy-tiny-people-near-huge-welcome-word-flat-illustration_74855-10808.jpg?t=st=1723893167~exp=1723896767~hmac=20b404de48d041ab40c66267f6eaa48ab707a5ac6dcd3614a46c65393ccf17c9&w=1060"
    })`,
    backgroundSize: "contain", // Adjusts the image to fit entirely within the container
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  }}
></div>



      <div className="relative  bg-zinc-200 flex items-center pl-5 pb-9 text-black p-2 pt-0">
        <div className="relative ml-20 mr-6">
          <img
            src={vendor.profilepicture}
            className="pt-0 mt-0 transform -translate-y-1/4 w-72 h-72 rounded-full bg-white border-8 border-black shadow-lg hidden sm:block"
          />
          {/* <LuFolderEdit
            className="absolute bottom-32 right-0 border-4 border-white text-white bg-gray-800 rounded-full p-1 w-12 h-12 cursor-pointer"
            onClick={triggerFileInput}
          /> */}
          {/* <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleImageChange}
          /> */}
        </div>
        <div className="p-5 pl-0 font-mono">
          <div className="flex items-center mb-3">
            {editingField === "ownerName" ? (
              <>
                {/* <input
                  type="text"
                  value={vendor.ownerName}
                  onChange={(e) =>
                    handleFieldChange("ownerName", e.target.value)
                  }
                  className="bg-white text-black p-1"
                /> */}
                {/* <button
                  onClick={() => handleFieldSubmit("ownerName")}
                  className="ml-2 bg-blue-500 text-white px-2 py-1 rounded"
                >
                  Submit
                </button> */}
              </>
            ) : (
              <div className="flex items-center">
                <h2 className="text-5xl font-normal">
                  {vendor.ownerName || "Owner Name"}
                </h2>
                {/* <FaEdit
                  className="ml-2 text-gray-600 cursor-pointer"
                  onClick={() => handleEditClick("ownerName")}
                /> */}
              </div>
            )}
          </div>
          <div className="flex items-center">
            {editingField === "companyName" ? (
              <>
                {/* <input
                  type="text"
                  value={vendor.companyName}
                  onChange={(e) =>
                    handleFieldChange("companyName", e.target.value)
                  }
                  className="bg-white text-black p-1"
                /> */}
                {/* <button
                  onClick={() => handleFieldSubmit("companyName")}
                  className="ml-2 bg-blue-500 text-white px-2 py-1 rounded"
                >
                  Submit
                </button> */}
              </>
            ) : (
              <div className="flex items-center">
                <p className="text-4xl font-mono">
                  {vendor.companyName || "Vendor Name"}
                </p>
                {/* <FaEdit
                  className="ml-2 text-gray-600 cursor-pointer"
                  onClick={() => handleEditClick("companyName")}
                /> */}
              </div>
            )}
          </div>
          <div className="flex items-center mt-4">
            {editingField === "address" ? (
              <>
                {/* <input
                  type="text"
                  value={vendor.address}
                  onChange={(e) => handleFieldChange("address", e.target.value)}
                  className="bg-white text-black p-1"
                /> */}
                {/* <button
                  onClick={() => handleFieldSubmit("address")}
                  className="ml-2 bg-blue-500 text-white px-2 py-1 rounded"
                >
                  Submit
                </button> */}
              </>
            ) : (
              <div className="flex items-center gap-2">
                <FaHome className="text-xl"/>
                <a
                  href={`https://maps.google.com/?q=${vendor.full_address}`}
                  className="text-xl  text-black hover:text-gray-700"
                >
                  {vendor.address || "Address"}
                </a>
              </div>
            )}
          </div>
          <div className="flex items-center gap-2">
            <FaPhoneVolume className="text-xl" />
            <a href={`tel:${vendor.phone}`} className="text-xl text-black hover:text-gray-700">
              {" "}
              +91-{vendor.phone || "Contact Number"}
            </a>
          </div>
          <div className="flex items-center gap-2">
            <IoEarth className="text-xl" />
            <a href={`${vendor.website}`}>
              <p className="text-xl underline text-blue-800 hover:text-blue-600">
              {vendor.website || "website"}
              </p>
            </a>
          </div>
          <div>
          <button
            onClick={() =>
              (window.location.href = `mailto:${vendor.email}?subject=Get in Touch&body=Hello, I would like to get in touch with you.`)
            }
            className="bg-red-600 hover:bg-red-500 m-4 p-1.5 pl-5 pr-5 rounded-3xl text-2xl text-white ml-10"
          >
            Message
          </button>

                </div>
        </div>
      </div>




      {/* Gallery section */}
      {/* <div className="flex-grow p-8 md:p-12">
        <h2 className="text-3xl font-semibold text-gray-800 mb-6">Gallery</h2>

        {images && images.length > 0 ? (
          <Carousel
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={true}
            interval={3000}
            stopOnHover={true}
            showStatus={false}
            className="max-w-4xl mx-auto"
          >
            {images.map((image, index) => (
              <div key={index} className="w-full h-96">
                <img
                  src={image}
                  alt={`Gallery Image ${index + 1}`}
                  className="w-full h-full object-cover rounded-lg shadow-md"
                />
              </div>
            ))}
          </Carousel>
        ) : (
          <p className="text-lg text-center text-gray-500">
            No images to show right now.
          </p>
        )}
      </div> */}


      {/* <div className="p-20 pt-6 bg-red-100">
        <h2 className="text-2xl font-semibold mb-4">
          Top Products and Services By vendor
        </h2>
        <a
          href="/all-events"
          className="text-black font-semibold hover:underline"
        >
          View All
        </a>
        <ProductComponent />
        <ServiceComponent />
      </div> */}

      {/* <div className="p-20 pt-10 bg-slate-100">
        <h2 className="text-2xl font-semibold mb-4 mt-0">Reviews</h2>
        <ReviewsComponent />
      </div> */}

      {/* <div className="p-20 pt-10 bg-slate-100 pr-96">
        <h2 className="text-2xl font-semibold mb-8">Notice Board</h2>
        <ChatBubble />
      </div> */}
      <hr className="black" />
      {/* Services Section */}
      <div className="fflex-grow p-8 md:p-12 bg-white shadow-xl rounded-2xl">
        <h1 className="text-3xl font-semibold text-gray-800 mb-8 ">Our Services</h1>

        {vendor.services && vendor.services.length > 0 ? (
          <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {vendor.services.map((service, index) => (
              <div
                key={service._id || index}
                className="p-6 bg-gradient-to-r from-gray-50 to-gray-100 rounded-xl shadow-md border border-black hover:shadow-2xl hover:scale-105 transform transition duration-300 ease-in-out"
              >
                <h3 className="text-xl font-semibold text-gray-700 text-center">
                  {service[0] || "No description available"}
                </h3>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-500">
            No services available for this vendor.
          </p>
        )}
      </div>
            {/* social Media  */}
            <div className="flex-grow p-8 md:p-12 bg-white shadow-lg rounded-lg">
      <h2 className="text-3xl font-semibold text-gray-800 mb-6">Connect with us on Socials</h2>
      <div className="flex flex-wrap justify-center gap-8 sm:gap-6 md:gap-8 lg:gap-10">
        {socialMediaLinks.map((social) => (
          <a
            key={social.name}
            href={social.link}
            target="_blank"
            rel="noopener noreferrer"
            className={`flex items-center justify-center w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-32 lg:h-32 bg-gray-50 rounded-full shadow-lg hover:shadow-xl transition-all duration-200 transform hover:scale-110`}
          >
            <div className={`text-gray-600 text-3xl sm:text-4xl md:text-5xl lg:text-6xl ${hoverColors[social.name]}`}>
              {socialIcons[social.name]}
            </div>
          </a>
        ))}
      </div>
    </div>

    <div className="p-8 bg-gray-100">
      <h1 className="text-3xl font-semibold text-gray-800 mb-6">Customer Reviews</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {reviews.map((review, index) => (
          <div
            key={index}
            className="p-5 bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105"
          >
            <div className="flex justify-center mb-4">
              {renderStars(review.rating)}
            </div>
            <p className="text-gray-600 text-center text-sm mb-2">{review.description}</p>
            <p className="text-gray-400 text-xs text-center">{new Date(review.date).toLocaleDateString()}</p>
          </div>
        ))}
      </div>
    </div>

      <Footer />
    </div>
  );
};

export default VendorDetails;
