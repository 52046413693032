import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

// Helper function to shuffle an array and return a subset
const getRandomBlogs = (blogs, count) => {
  const shuffledBlogs = [...blogs].sort(() => 0.5 - Math.random());
  return shuffledBlogs.slice(0, count);
};

const HomeBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch blogs from backend API
    const fetchBlogs = async () => {
      try {
        const response = await axios.get("https://procydia.onrender.com/api/blog/blog");
        setBlogs(getRandomBlogs(response.data, 3)); // Select 3 random blogs
      } catch (err) {
        setError("Failed to load blogs. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <div className="mx-auto p-10 w-full mt-0">
        <h1 className="text-3xl font-bold mb-8 text-center">Explore Our Blogs ✍️📰</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogs.map((blog) => (
            <div key={blog.id} className="bg-slate-100 shadow-lg rounded-lg overflow-hidden">
              <div className="p-6">
                <h2 className="text-2xl font-bold mb-2">{blog.title}</h2>
                <p className="text-gray-600 font-semibold mb-4">{blog.excerpt}</p>
                <Link
                  to={`/blogs/${blog.id}`}
                  className="text-blue-800 hover:underline font-semibold"
                >
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
        {/* View All Button */}
        <div className="flex justify-center mt-8">
          <Link
            to="/blogs" // Redirect to the main Blogs page
            className="bg-blue-700 text-white px-5 py-2 rounded-lg font-semibold hover:bg-blue-800"
          >
            View All
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeBlogs;
