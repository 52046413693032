import React, { useState, useEffect } from "react";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles

const sampleDjList = [
  {
    id: 1,
    name: "DJ Mike",
    subname: "Party Specialist",
    image: "DJ Mike.jpeg",
    profileLink: "/profile/66cc6a467765c256351abe15",
    contactNumber: "+1234567890",
  },
];

const Entertainment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    eventDescription: "",
    eventDate: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        "https://procydia.onrender.com/api/entertainment/create",
        formData
      );
      setFormSubmitted(true);
      gsap.fromTo(
        ".popup-container",
        { opacity: 0, y: -50 },
        { opacity: 1, y: 0, duration: 0.5, ease: "power3.out" }
      );
      setTimeout(() => {
        window.location.href = "/";
      }, 5000);
    } catch (err) {
      console.error("Error submitting form:", err);
    }
  };
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await axios.get(
          "https://procydia.onrender.com/api/vendor/entertainment/vendors"
        );
        if (response.data.length > 0) {
          setVendors(response.data);
        } else {
          setVendors(sampleDjList); // If no vendors available, show sample data
        }
      } catch (err) {
        // Properly define 'err' here
        console.error("Error fetching vendors:", err); // 'err' instead of 'error'
        setVendors(sampleDjList); // In case of error, fallback to sample data
      } finally {
        setLoading(false);
      }
    };
    fetchVendors();
  }, []);

  return (
    <div>
      <Navbar />
      <div className="bg-white text-black p-4 sm:p-6 md:p-8 lg:p-10 mt-8 w-full flex flex-col lg:flex-row lg:space-x-10">
        
        {/* Left Section: Form */}
        <div className="lg:w-2/5 p-6 sm:p-8 md:p-10 lg:p-14 bg-gray-50 rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold mb-6 text-gray-800 text-center">
            Book Your Entertainment Service Now
          </h2>
          <p className="text-center text-gray-600 mb-4">
            We’re excited to make your event unforgettable! Please fill out the details below, and we’ll get back to you soon.
          </p>
          
          <form
            onSubmit={handleSubmit}
            className="space-y-4 bg-white p-4 sm:p-6 md:p-5 rounded-lg shadow-md"
          >
            <div>
              <label
                className="block text-gray-700 text-lg font-bold mb-2"
                htmlFor="name"
              >
                Name
              </label>
              <input
                className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-200"
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>
  
            <div>
              <label
                className="block text-gray-700 text-lg font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-200"
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email address"
                required
              />
            </div>
  
            <div>
              <label
                className="block text-gray-700 text-lg font-bold mb-2"
                htmlFor="phone"
              >
                Phone
              </label>
              <input
                className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-200"
                type="tel"
                name="phone"
                id="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Enter your phone number"
                required
              />
            </div>
  
            <div>
              <label
                className="block text-gray-700 text-lg font-bold mb-2"
                htmlFor="eventDescription"
              >
                Event Description
              </label>
              <textarea
                className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-200"
                name="eventDescription"
                id="eventDescription"
                value={formData.eventDescription}
                onChange={handleChange}
                placeholder="Describe your event"
                required
              />
            </div>
  
            <div>
              <label
                className="block text-gray-700 text-lg font-bold mb-2"
                htmlFor="eventDate"
              >
                Event Date
              </label>
              <input
                className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-200"
                type="date"
                name="eventDate"
                id="eventDate"
                value={formData.eventDate}
                onChange={handleChange}
                required
              />
            </div>
  
            <button
              type="submit"
              className="w-full bg-blue-600 text-white font-semibold py-3 rounded-md hover:bg-blue-700 transition duration-300"
            >
              Submit
            </button>
          </form>
  
          {formSubmitted && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold mb-4">Details Submitted</h2>
                <p>Your details have been submitted successfully! Redirecting to the home page...</p>
              </div>
            </div>
          )}
        </div>
  
        {/* Right Section: Vendors */}
        <div className="lg:w-3/5 max-w-full mx-auto mt-10 lg:mt-0">
          <h1 className="text-3xl font-bold text-center mb-8">
            Entertainment DJ Services
          </h1>
          {loading ? (
            <p className="text-center">Loading vendors...</p>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8">
              {vendors.map((vendor) => (
                <div
                  key={vendor._id}
                  className="bg-white shadow-lg rounded-lg overflow-hidden"
                >
                  <img
                    src={vendor.profilepicture}
                    alt={vendor.companyName}
                    className="w-full h-48 object-contain"
                  />
                  <div className="p-6">
                    <h2 className="text-2xl font-semibold">
                      {vendor.companyName}
                    </h2>
                    <p className="text-gray-600">{vendor.ownerName}</p>
                    <div className="mt-4 flex justify-between items-center">
                      <a
                        href={`tel:${vendor.phone}`}
                        className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition"
                      >
                        Contact Now
                      </a>
                      <Link
                        to={`/profile/${vendor._id}`}
                        className="text-blue-500 border border-blue-500 py-2 px-4 rounded-md hover:bg-blue-100 transition"
                      >
                        View Profile
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
  
};

export default Entertainment;
