import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import ShopByDestinations from "./ShopByDestinations";
import WhatsHappening from "./Whatshappening";
import axios from "axios";
import SocialMedia from "./Socials";

// Lazy load components
const Navbar = lazy(() => import("../Navbaar&Footer/Navbar"));
const Footer = lazy(() => import("../Navbaar&Footer/Footer"));
const ExploreTopCategories = lazy(() => import("./ExploreTopCategories"));
const Blogs = lazy(() => import("../Blogs/HomeBlogs"));

const Home = () => {
  const bannerRef = useRef(null);

  useEffect(() => {
    // Add your animation and interval code here if needed
    const loaddatabase = async () => {
      try {
        const response = await axios.get('https://procydia.onrender.com/api/vendor/mblock/vendors');
      } catch (err) {
        console.error("Error fetching vendors:", err);
      }
    };
    loaddatabase();
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Navbar />
      <Banner ref={bannerRef} />
      <ExploreTopCategories />
      <ShopByDestinations />
      <WhatsHappening />
      <SocialMedia />
      <Blogs />
      <Footer />
    </Suspense>
  );
};

const Banner = React.forwardRef((props, ref) => (
  <div
    ref={ref}
    className="bg-slate-400 text-white p-4 sm:p-6 md:p-10 flex flex-col md:flex-row items-center shadow-3xl w-full mt-11"
    style={{
      backgroundImage: "url('3.png')", // Make sure this image is optimized
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "67vh",
      minHeight: 350,
      maxHeight: 512,
    }}
  >
    <div className="flex-2 p-4 md:p-8">
      <div className="flex items-center mb-4">
        <a href="/about">
          <p className="text-slate-200 hover:text-white">Learn more about Procydia</p>
        </a>
      </div>
      <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 shadow-sm text-white">
        Proceed your ideas with Procydia
      </h2>
      <SearchBar />
      <FrequentlySearched />
    </div>
    <div className="flex-1 flex justify-center items-center relative mt-4 md:mt-0"></div>
  </div>
));

const debounce = (func, delay) => {
  let debounceTimer;
  return function (...args) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(this, args), delay);
  };
};

const SearchBar = React.memo(() => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  const searchOptions = [
    { name: "Blogs", path: "/blogs" },
    { name: "About", path: "/about" },
    { name: "Contact", path: "/contact" },
    { name: "Venue", path: "/weddingvendors" },
    { name: "Decoration", path: "/weddingvendors" },
    { name: "Investment", path: "/weddingvendors" },
    { name: "Catering", path: "/weddingvendors" },
    { name: "Entertainment", path: "/weddingvendors" },
    { name: "Crew", path: "/crew" },
    { name: "Gift", path: "/weddingvendors" },
    {name: "Construction Vendors", path: "/constructionvendors"},
    {name: "Construction Work", path: "/constructionvendors"},
    {name: "Wedding", path: "/weddingvendors"},
    {name: "plan catering", path: "/cateringplan"},
    {name: "M Block market", path: "/mblockmkt"},
    {name: "Lajpat Nagar market", path: "/lajpatmkt"},
    {name: "Khan market", path: "/khanmkt"},
    {name: "Sarojini market", path: "/sarojinimkt"},
    {name: "Rajouri market", path: "/rajourigardenmarket"},
    {name: "Chandni Chowk market", path: "/chandnichowkmkt"},
  ];

  useEffect(() => {
    if (query.length > 0) {
      const filteredSuggestions = searchOptions.filter((option) =>
        option.name.toLowerCase().includes(query.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [query]);

  const handleInputChange = debounce((value) => {
    setQuery(value);
  }, 50);

  const handleSearch = (path) => {
    navigate(path || "/");
    setQuery("");
    setSuggestions([]);
  };

  return (
    <div className="relative w-full max-w-lg mb-6">
      <div className="flex items-center border border-border rounded-full bg-white">
        <img src="thunder.png" alt="Help icon" className="w-6 md:w-8 h-5 pl-3" />
        <input
          type="text"
          placeholder="Ask Procydia..."
          className="w-full p-2 focus:outline-none text-black"
          value={query}
          onChange={(e) => handleInputChange(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSearch(suggestions.length ? suggestions[0].path : "/");
            }
          }}
        />
<button
  // onClick={() => handleSearch(suggestions.length ? suggestions[0].path : "/")}
  className="h-full px-3 py-2 bg-white text-black rounded-full flex items-center justify-center"
  aria-label="Search"
>
  <i className="fa-solid fa-magnifying-glass" aria-hidden="true"></i>
</button>
      </div>
      {suggestions.length > 0 && (
        <ul className="absolute z-10 bg-white text-black border border-white rounded-md w-full mt-1">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className="px-4 py-2 border-2 border- hover:bg-gray-100 cursor-pointer"
              onClick={() => handleSearch(suggestion.path)}
            >
              {suggestion.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
});

const FrequentlySearched = React.memo(() => (
  <div className="flex items-center mb-4 md:mb-8">
    <div className="flex flex-col items-center">
      {/* Frequently searched content goes here */}
    </div>
  </div>
));

export default Home;
