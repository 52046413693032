import React, { useEffect, useState } from "react";
import {
  CarouselProvider,
  Slider,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import useWindowSize from "./useWindowSize";
import SlideItem from "./SlideItem";
import axios from "axios";

export default function WhatsHappening() {
  const [slidesData, setSlidesData] = useState([]); // State to hold the slides data
  const size = useWindowSize();

  // Sample data to display while the API is loading
  const sampleData = [
    {
      id: 1,
      imgSrc: "https://assets-in.bmscdn.com/nmcms/events/banner/desktop/media-desktop-carnival-by-pawasana-0-2024-10-25-t-11-8-26.jpg",
      catalog: "New Delhi",
      title: "Carnival By Pawasana",
      url: "https://in.bookmyshow.com/events/carnival-by-pawasana/ET00417460"
    },
    {
      id: 2,
      imgSrc: "https://assets-in.bmscdn.com/nmcms/events/banner/desktop/media-desktop-india-art-festival-0-2024-8-22-t-9-51-3.jpg",
      catalog: "Delhi",
      title: "India Art Festival",
      url: "https://in.bookmyshow.com/events/india-art-festival/ET00408795"
    },
    {
      id: 3,
      imgSrc: "https://assets-in.bmscdn.com/nmcms/events/banner/desktop/media-desktop-the-tutelage-bimal-das-gupta-0-2024-10-20-t-5-49-17.jpg",
      catalog: "Delhi",
      title: "The Tutelage - Bimal Das Gupta",
      url: "https://in.bookmyshow.com/events/the-tutelage-bimal-das-gupta/ET00416712"
    }
  ];

  // Fetch slides data from the backend API
  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const response = await axios.get("https://procydia.onrender.com/api/events"); // Replace with your API endpoint
        setSlidesData(response.data); // Update the slides data state
      } catch (error) {
        console.error("Error fetching slides data:", error);
        // Use sample data in case of an error
        setSlidesData(sampleData);
      }
    };

    fetchSlides();
  }, []);

  // Determine the number of visible slides based on window width
  let visibleSlides = 4; // Default for large screens
  if (size.width <= 1024 && size.width > 768) {
    visibleSlides = 2; // For medium screens
  } else if (size.width <= 768) {
    visibleSlides = 1; // For small screens
  }

  // Adjust visibleSlides if slidesData length is less than the default
  visibleSlides = Math.min(visibleSlides, slidesData.length);

  return (
    <div className="p-10">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold">Explore Events Near You 🗓️📢</h2>
        <p className="text-gray-600">Take a Look Trending Events</p>
      </div>
      <div className="mx-auto py-24 sm:py-8 px-4">
        <CarouselProvider
          naturalSlideWidth={100}
          isIntrinsicHeight={true}
          totalSlides={slidesData.length}
          visibleSlides={visibleSlides}
          step={1}
          infinite={true}
        >
          <div className="relative flex items-center justify-center">
            {/* Previous Button */}
            {slidesData.length > 1 && (
              <ButtonBack
                role="button"
                aria-label="Slide backward"
                className="absolute z-30 left-0 ml-4 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer"
              >
                <svg
                  width={12}
                  height={20}
                  viewBox="0 0 12 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 1L3 10L11 19"
                    stroke="white"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ButtonBack>
            )}

            {/* Slider */}
            <Slider>
              <div className="flex h-full gap-8 items-center justify-start transition ease-out duration-700">
                {slidesData.map((slide, index) => (
                  <SlideItem slide={slide} index={index} key={slide._id} />
                ))}
              </div>
            </Slider>

            {/* Next Button */}
            {slidesData.length > 1 && (
              <ButtonNext
                role="button"
                aria-label="Slide forward"
                className="absolute z-30 right-0 mr-4 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer"
              >
                <svg
                  width={12}
                  height={20}
                  viewBox="0 0 12 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L9 10L1 19"
                    stroke="white"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ButtonNext>
            )}
          </div>
        </CarouselProvider>
      </div>
    </div>
  );
}
