import React, { useState, useEffect } from "react";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles


const sampleDjList = [
  {
    id: 1,
    name: "DJ Mike",
    subname: "Party Specialist",
    image: "DJ Mike.jpeg",
    profileLink: "/profile/66cc6a467765c256351abe15",
    contactNumber: "+1234567890",
  },
];

const Investment = () => {
  
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await axios.get(
          "https://procydia.onrender.com/api/vendor/Investment/vendors"
        );
        if (response.data.length > 0) {
          setVendors(response.data);
        } else {
          setVendors(sampleDjList); // If no vendors available, show sample data
        }
      } catch (err) {
        // Properly define 'err' here
        console.error("Error fetching vendors:", err); // 'err' instead of 'error'
        setVendors(sampleDjList); // In case of error, fallback to sample data
      } finally {
        setLoading(false);
      }
    };
    fetchVendors();
  }, []);

  return (
<div>
  <Navbar />
  <div className="bg-white text-black p-4 sm:p-6 md:p-8 lg:p-10 mt-8 w-full flex flex-col lg:flex-row lg:space-x-10">
    
    {/* Left Section: Interesting Facts */}
    <div className="lg:w-2/5 bg-gray-100 p-6 mt-10 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6">Interesting Facts About Investment🕵️‍♀️</h2>
      <ul className="list-disc pl-5 space-y-4 text-lg text-gray-700">
        <li>Compounding interest can turn small investments into significant wealth over time.</li>
        <li>Investing in stocks has historically provided higher returns compared to other asset classes.</li>
        <li>Diversification reduces risk by spreading investments across different assets.</li>
        <li>Long-term investors generally outperform short-term traders.</li>
        <li>Inflation can erode the purchasing power of savings, making investment essential.</li>
        <li>Real estate is one of the most popular long-term investments.</li>
        <li>Start investing early to take advantage of time in the market.</li>
      </ul>
    </div>
    
    {/* Right Section: Vendors */}
    <div className="lg:w-3/5 max-w-full mx-auto mt-10 lg:mt-0">
      <h1 className="text-xl font-bold text-center mb-8">
        Investment Services
      </h1>
      {loading ? (
        <p className="text-center">Loading vendors...</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8">
          {vendors.map((vendor) => (
            <div
              key={vendor._id}
              className="bg-white shadow-lg rounded-lg overflow-hidden"
            >
              <img
                src={vendor.profilepicture}
                alt={vendor.companyName}
                className="w-full h-48 object-contain"
              />
              <div className="p-6">
                <h2 className="text-2xl font-semibold">
                  {vendor.companyName}
                </h2>
                <p className="text-gray-600">{vendor.ownerName}</p>
                <div className="mt-4 flex justify-between items-center">
                  <a
                    href={`tel:${vendor.phone}`}
                    className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition"
                  >
                    Contact Now
                  </a>
                  <Link
                    to={`/profile/${vendor._id}`}
                    className="text-blue-500 border border-blue-500 py-2 px-4 rounded-md hover:bg-blue-100 transition"
                  >
                    View Profile
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
  <Footer />
</div>

  );
  
};

export default Investment;
