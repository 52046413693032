import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const MarketList = () => {
  const [markets, setMarkets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMarkets = async () => {
      try {
        const response = await axios.get('https://procydia.onrender.com/api/markets');
        console.log(response.data); // Check if the data structure is correct
        setMarkets(response.data.markets || []); // Ensure it's an array or fallback to empty
        setLoading(false);
      } catch (err) {
        console.error("Error fetching markets:", err);
        setError("Failed to fetch markets.");
        setLoading(false);
      }
    };

    fetchMarkets();
  }, []);

  if (loading) {
    return <div className="text-center mt-10">Loading markets...</div>;
  }

  if (error) {
    return <div className="text-center mt-10 text-red-500">{error}</div>;
  }

  return (
<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
  {Array.isArray(markets) && markets.length > 0 ? (
    markets.map((market) => (
      <Link
        to={`/market/${market.id}`}
        key={market.id}
        className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-shadow duration-300"
      >
        <h2 className="text-2xl font-semibold mb-2">{market.name}</h2>
        {/* Optionally, add an image or description */}
        <p className="text-gray-600">Explore vendors in {market.name}</p>
      </Link>
    ))
  ) : (
    <div>No markets available</div>
  )}
</div>

  );
};

export default MarketList;
