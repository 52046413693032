import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../Navbaar&Footer/Footer";
import Navbar from "../Navbaar&Footer/Navbar";
import axios from "axios";

const Construction = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [date, setDate] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [query, setQuery] = useState("");
    const [selectedLabel, setSelectedLabel] = useState([]); // Changed to an array
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const images = [
        { src: "civilwork.jpg", label: "Civil Work" },
        { src: "electricalwork.webp", label: "Electrical Work" },
        { src: "plumbing.jpg", label: "Plumbing" },
        { src: "polishing.webp", label: "Polishing" },
        { src: "fabrication.webp", label: "Fabrication" }
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation check for image selection
        // if (selectedLabel.length === 0) {
        //     alert("Please select at least one category.");
        //     return;
        // }

        const formData = {
            name,
            email,
            date,
            phoneNumber,
            query,
            // selectedLabel,
        };

        try {
            const response = await axios.post(
                "https://procydia.onrender.com/api/construction/submit",
                formData
            );
            console.log("Form submitted successfully:", response.data);

            setError("");
            setIsSubmitted(true);

            setTimeout(() => {
                navigate("/");
            }, 3000);
        } catch (error) {
            console.error("Error submitting form:", error.response || error.message);
            setError("There was an issue submitting the form. Please try again.");
        }
    };

    const toggleLabel = (label) => {
        setSelectedLabel((prev) => 
            prev.includes(label) ? prev.filter((l) => l !== label) : [...prev, label]
        );
    };

    return (
        <div className="bg-gray-50 min-h-screen">
            <Navbar />
            <div className="m-20">
                <h2 className="text-3xl font-bold mb-6 text-gray-800 text-center">
                    Book Your Construction Service Now
                </h2>
                <p className="text-center text-gray-600 mb-4">
                    Please fill out the details below, and we’ll get back to you soon.
                </p>
                <div className="max-w-xl mx-auto mt-12 mb-10 p-6 bg-white rounded-lg shadow-md">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        {/* Form Fields */}
                        <div className="flex flex-col">
                            <label htmlFor="name" className="mb-2 font-medium text-gray-700">Name:</label>
                            <input
                                type="text"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter your name"
                                required
                                className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="email" className="mb-2 font-medium text-gray-700">Email:</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                                required
                                className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="date" className="mb-2 font-medium text-gray-700">Date:</label>
                            <input
                                type="date"
                                id="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                required
                                className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="phoneNumber" className="mb-2 font-medium text-gray-700">Phone Number:</label>
                            <input
                                type="tel"
                                id="phoneNumber"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                placeholder="Enter your phone number"
                                required
                                className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                        {/* New Query Field */}
                        <div className="flex flex-col">
                            <label htmlFor="query" className="mb-2 font-medium text-gray-700">Your Query:</label>
                            <textarea
                                id="query"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                placeholder="Enter your query or additional details"
                                required
                                className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            ></textarea>
                        </div>
                        {/* <div className="flex flex-col">
                            <label className="mb-2 font-medium text-gray-700">Select Categories:</label>
                            <div className="flex flex-wrap justify-center gap-4 mt-2">
                                {images.map((img, index) => (
                                    <div key={index} className="text-center">
                                        <img
                                            src={img.src}
                                            alt={`Option ${index + 1}`}
                                            className={`w-24 h-24 object-cover rounded-lg cursor-pointer border-2 transition-transform transform hover:scale-105 ${selectedLabel.includes(img.label) ? "border-blue-500" : "border-gray-300"}`}
                                            onClick={() => toggleLabel(img.label)}
                                        />
                                        <p className="mt-2 text-sm text-gray-600">{img.label}</p>
                                    </div>
                                ))}
                            </div>
                        </div> */}
                        <button type="submit" className="w-full py-3 bg-blue-500 text-white font-medium rounded-md hover:bg-blue-600 transition-colors">
                            Submit
                        </button>
                    </form>
                </div>

                {/* Error Message */}
                {error && (
                    <div className="mt-4 p-4 bg-red-100 text-red-600 rounded-md">
                        <p>{error}</p>
                    </div>
                )}
            </div>

            {/* Pop-up for successful submission */}
            {isSubmitted && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h2 className="text-2xl font-bold mb-4">Details Submitted</h2>
                        <p>Your details have been submitted successfully! Redirecting to the home page...</p>
                    </div>
                </div>
            )}
            <Footer />
        </div>
    );
}

export default Construction;
