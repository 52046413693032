import React from "react";
import { Slide } from "pure-react-carousel";

export default function SlideItem({ slide, index }) {
  return (
    <Slide index={index} key={slide.id}>
      <a href={`${slide.url}`}>
        <div className="flex flex-shrink-0 relative w-full sm:w-auto aspect-w-16 aspect-h-9">
          <img
            src={slide.imgSrc}
            alt={slide.alt}
            className="object-cover object-center w-96 h-60 transform transition-transform duration-500 ease-in-out hover:scale-105"
          />
          <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6 flex flex-col justify-end">
            <h2 className="text-xl lg:text-xl leading-4 lg:leading-5 text-white">
              {slide.catalog}
            </h2>
            <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">
              {slide.title}
            </h3>
          </div>
        </div>
      </a>
    </Slide>
  );
}
