import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { trackConversion } from '../utils/conversionTracker'
import axios from "axios";
import { gsap } from "gsap";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
  });

  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (showPopup) {
      gsap.fromTo(
        ".popup-container",
        { scale: 0 },
        { scale: 1, duration: 0.5, ease: "elastic.out(1, 0.3)" }
      );
      gsap.to(".tick-icon", { rotation: 360, duration: 1, ease: "power4.out" });
    }
  }, [showPopup]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {

        // Trigger Google Ads conversion tracking
        trackConversion();

    e.preventDefault();

    try {
      const response = await axios.post(
        "https://procydia.onrender.com/api/contacts",
        formData
      );

      if (response.status === 201) {
        setShowPopup(true);

        setTimeout(() => {
          setShowPopup(false);
          navigate("/");
        }, 2000);
      }
    } catch (error) {
      console.error("There was an error submitting the form!", error);
    }
  };

  return (
    <>
      <Navbar />
      <div className="max-w-lg mx-auto mt-28 border-2 shadow-lg mb-10 rounded-xl p-8 bg-white text-gray-800">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
          We're Here to Help
        </h2>
        <p className="text-center mb-6 text-lg text-gray-700">
          At Procydia, we're committed to helping you achieve your goals. Whether you're starting a new venture or expanding an existing business, we're here to support you every step of the way.
        </p>
        <p className="text-center mb-10 text-lg text-gray-700">
          Fill out the form below, and one of our team members will reach out to you shortly. Your success is our priority.
        </p>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block mb-2 text-lg font-medium text-gray-700">
              Name:
            </label>
            <input
              type="text"
              placeholder="Enter your name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 border border-gray-300"
            />
          </div>
          <div>
            <label className="block mb-2 text-lg font-medium text-gray-700">
              Phone:
            </label>
            <input
              type="text"
              placeholder="Enter your contact number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 border border-gray-300"
            />
          </div>
          <div>
            <label className="block mb-2 text-lg font-medium text-gray-700">
              Email:
            </label>
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 border border-gray-300"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-3 rounded-md text-xl font-semibold hover:bg-blue-700 transition-all shadow-md"
          >
            Submit
          </button>
        </form>

        {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="popup-container bg-white p-8 rounded-lg text-center shadow-2xl">
              <div className="flex justify-center mb-4">
                <svg
                  className="tick-icon w-20 h-20 text-green-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <h1 className="text-2xl font-bold text-gray-800">
                Details Submitted Successfully!
              </h1>
              <h2 className="text-xl font-bold text-gray-800">
                We will reach out to you shortly.
              </h2>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ContactForm;
