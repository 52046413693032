import React, { useEffect, useRef, useState,useNavigate } from "react";
import axios from "axios";
import { Navigate, useParams } from "react-router-dom";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";
import { FaEdit } from "react-icons/fa";
import ProductComponent from "./Products";
import ServiceComponent from "../Home/services";
import ReviewsComponent from "./Reviews";
import ChatBubble from "./ChatBubble";
import { BsInstagram } from "react-icons/bs";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";
import { IoEarth } from "react-icons/io5";
import { LuFolderEdit } from "react-icons/lu";
import PhotoUploadGallery from "../Gallery/UserGallery";

const VendorDetailsed = () => {
  const { vendorId } = useParams();
  const [vendor, setVendor] = useState({
    companyName: "",
    ownerName: "",
    profilepicture: "",
    backgroundpicture: "",
    address: "",
    phone: "",
    instagram: "",
    facebook: "",
    linkedIn: "",
    email: "",
  });
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [editingField, setEditingField] = useState(null);
  const [uploadField, setUploadField] = useState(null); // Track which field (profile or background) to upload
  const fileInputRef = useRef(null);



  useEffect(() => {
    const fetchVendorDetails = async () => {
      try {
        const response = await axios.get(
          `https://procydia.onrender.com/api/vendor/${vendorId}`
        );
        setVendor(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVendorDetails();
  }, [vendorId]);

  const handleImageChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  // Handle image selection
  const handleImageChange1 = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 5) {
      setError("You can only upload up to 5 images.");
      return;
    }
    
    // Preview selected images
    const imagePreviews = files.map((file) => URL.createObjectURL(file));
    setPreviewImages(imagePreviews);
    setSelectedImages(files);
    setError("");
  };
  

// Handle form submission
// const handleSubmit = async (e) => {
//   e.preventDefault();

//   if (selectedImages.length === 0) {
//     setError("Please select at least one image.");
//     return;
//   }

//   // Create form data to send to API
//   const formData = new FormData();
  
//   // Append images to the 'gallery' field in form data
//   selectedImages.forEach((image) => {
//     formData.append('gallery', image); // Ensure the field name matches the one in your schema
//   });

//   // Optionally, append other fields if needed (e.g., companyName, ownerName, etc.)
//   // formData.append('companyName', companyName);
//   // formData.append('ownerName', ownerName);
//   // Add other form data fields as required

//   try {
//     const response = await axios.put(
//       `https://procydia.onrender.com/api/vendor/${vendorId}`,
//       { [field]: vendor[field] },
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     console.log("Images and vendor data updated successfully", response.data);
//     // You can add success popup/redirect as per your requirement
//   } catch (error) {
//     console.error("Error uploading images", error);
//   }
// };


  const handleUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("profilepicture", selectedFile); // The name here should match the backend

    try {
      await axios.post(
        `https://procydia.onrender.com/api/vendor/upload/${vendorId}`,
        formData
      );
      // Refresh vendor data
      const response = await axios.get(
        `https://procydia.onrender.com/api/vendor/${vendorId}`
      );
      setVendor(response.data);
      setSelectedFile(null);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const triggerFileInput = (field) => {
    setUploadField(field); // Set the field before triggering file input
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (selectedFile) {
      handleUpload();
    }
  }, [selectedFile]);

  const handleEditClick = (field) => {
    setEditingField(field);
  };

  const handleFieldChange = (field, value) => {
    setVendor((prevVendor) => ({
      ...prevVendor,
      [field]: value,
    }));
  };

  const handleFieldSubmit = async (field) => {
    try {
      const token = localStorage.getItem("authToken");

      if (!token) {
        throw new Error("No token found");
      }

      const response = await axios.put(
        `https://procydia.onrender.com/api/vendor/${vendorId}`,
        { [field]: vendor[field] },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const updatedVendorResponse = await axios.get(
        `https://procydia.onrender.com/api/vendor/${vendorId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setVendor(updatedVendorResponse.data);
      setEditingField(null);
    } catch (error) {
      console.error(
        "Error updating field:",
        error.response ? error.response.data : error.message
      );
      setError("Error updating field. Please try again.");
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <Navbar />
      <div
  className="relative mt-16 w-full h-80 shadow-md bg-center bg-cover"
  style={{
    backgroundImage: `url(${
      vendor.backgroundpicture ||
      "https://img.freepik.com/free-vector/happy-tiny-people-near-huge-welcome-word-flat-illustration_74855-10808.jpg?t=st=1723893167~exp=1723896767~hmac=20b404de64d041ab40c66267f6eaa24ab707a5ac6dcd3614a46c65393ccf17c9&w=1060"
    })`,
    backgroundSize: 'contain',   // Ensures the full image is visible within the container
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'no-repeat', // Prevents image repetition
  }}
>
</div>

      <div className="relative flex items-center pl-5 pb-9 text-black p-2 pt-0">
        <div className="relative ml-20 mr-6">
          <img
            src={vendor.profilepicture}
            className="pt-0 mt-0 transform -translate-y-1/4 w-72 h-72 rounded-full border-8 border-black shadow-lg"
            alt="Profile"
          />
          <LuFolderEdit
            className="absolute bottom-32 right-0 border-4 border-white text-white bg-gray-800 rounded-full p-1 w-12 h-12 cursor-pointer"
            onClick={triggerFileInput}
          />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
        </div>
        <div className="p-5 pl-0">
          <div className="flex items-center">
            {editingField === "ownerName" ? (
              <>
                <input
                  type="text"
                  value={vendor.ownerName}
                  onChange={(e) =>
                    handleFieldChange("ownerName", e.target.value)
                  }
                  className="bg-white text-black rounded-md p-1"
                />
                <button
                  onClick={() => handleFieldSubmit("ownerName")}
                  className="ml-2 bg-blue-500 text-white px-2 py-1 rounded"
                >
                  Submit
                </button>
              </>
            ) : (
              <div className="flex items-center">
                <h2 className="text-3xl font-semibold">
                  {vendor.ownerName || "Owner Name"}
                </h2>
                <FaEdit
                  className="ml-2 text-gray-600 cursor-pointer"
                  onClick={() => handleEditClick("ownerName")}
                />
              </div>
            )}
          </div>
          <div className="flex items-center">
            {editingField === "companyName" ? (
              <>
                <input
                  type="text"
                  value={vendor.companyName}
                  onChange={(e) =>
                    handleFieldChange("companyName", e.target.value)
                  }
                  className="bg-white text-black rounded-md p-1"
                />
                <button
                  onClick={() => handleFieldSubmit("companyName")}
                  className="ml-2 bg-blue-500 text-white px-2 py-1 rounded"
                >
                  Submit
                </button>
              </>
            ) : (
              <div className="flex items-center">
                <p className="text-2xl">
                  {vendor.companyName || "Vendor Name"}
                </p>
                <FaEdit
                  className="ml-2 text-gray-600 cursor-pointer"
                  onClick={() => handleEditClick("companyName")}
                />
              </div>
            )}
          </div>
          <div className="flex items-center">
            {editingField === "address" ? (
              <>
                <input
                  type="text"
                  value={vendor.address}
                  onChange={(e) => handleFieldChange("address", e.target.value)}
                  className="bg-white text-black rounded-md p-1"
                />
                <button
                  onClick={() => handleFieldSubmit("address")}
                  className="ml-2 bg-blue-500 text-white px-2 py-1 rounded"
                >
                  Submit
                </button>
              </>
            ) : (
              <div className="flex items-center gap-2">
                <FaHome />
                <p className="text-lg">{vendor.address || "Address"}</p>
                <FaEdit
                  className="ml-2 text-gray-600 cursor-pointer"
                  onClick={() => handleEditClick("address")}
                />
              </div>
            )}
          </div>
          <div className="flex flex-auto gap-2">
            <FaPhoneVolume />
            <p className="text-sm">{vendor.phone || "Contact Number"}</p>
          </div>
          <div className="flex gap-2">
            <IoEarth />
            <a href="#">
              <p className="text-sm underline text-blue-800">
                {vendor.website || "Website Link"}
              </p>
            </a>
          </div>
          <button
            onClick={() =>
              (window.location.href = `mailto:${vendor.email}?subject=Get in Touch&body=Hello, I would like to get in touch with you.`)
            }
            className="bg-red-600 mt-2 p-2 rounded-xl text-xl text-white"
          >
            Get in touch
          </button>

        </div>
      </div>

      <div className="p-20 pt-5 bg-gray-100">
        <h2 className="text-2xl font-semibold mb-4">Socials</h2>
        <div className="flex gap-8 ml-0 m-10 mb-0">
          <a
            href={vendor.instagram}
            className="flex items-center justify-center text-2xl text-white bg-pink-500 rounded-xl p-4 hover:bg-pink-400 transition duration-300"
          >
            <BsInstagram className="mr-2" />
            Instagram
          </a>
          <a
            href={vendor.facebook}
            className="flex items-center justify-center text-2xl text-white bg-blue-600 rounded-xl p-4 hover:bg-blue-500 transition duration-300"
          >
            <FaSquareFacebook className="mr-2" />
            Facebook
          </a>
          <a
            href={vendor.linkedIn}
            className="flex items-center justify-center text-2xl text-white bg-blue-500 rounded-xl p-4 hover:bg-blue-400 transition duration-300"
          >
            <FaLinkedin className="mr-2" />
            LinkedIn
          </a>
        </div>
      </div>

      {/* <div className="p-20 pt-6 bg-red-100">
        <h2 className="text-2xl font-semibold mb-4">
          Top Products and Services By Vendor
        </h2>
        <a
          href="/all-events"
          className="text-black font-semibold hover:underline"
        >
          View All
        </a>
        <ProductComponent />
        <ServiceComponent />
      </div>

      <div className="p-20 pt-10 bg-slate-100">
        <h2 className="text-2xl font-semibold mb-4 mt-0">Reviews</h2>
        <ReviewsComponent />
      </div>

      <div className="p-20 pt-10 bg-slate-100 pr-96">
        <h2 className="text-2xl font-semibold mb-8">Notice Board</h2>
        <ChatBubble />
      </div> */}
          {/* Add Logout Button Here */}
      {/* <div className="flex justify-end p-4">
        <button
          onClick={handleLogout}
          className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        >
          Logout
        </button>
      </div> */}
      {/* Media upload */}
          <div className="photo-upload-gallery p-20 pt-5 ">
      <h2 className='text-2xl font-semibold mb-4'>Upload Media</h2>
      <form onSubmit={handleFieldSubmit}>
        <input 
          type="file" 
          accept="image/*" 
          multiple 
          onChange={handleImageChange1} 
          className="mb-4" 
        />
        {error && <p className="error-message text-red-500">{error}</p>}
        
        <div className="image-preview-grid grid grid-cols-3 gap-4">
          {previewImages.map((image, index) => (
            <div key={index} className="image-preview">
              <img 
                src={image} 
                alt={`Preview ${index + 1}`} 
                className="w-full h-32 object-cover" 
              />
            </div>
          ))}
        </div>

        <button 
          type="submit" 
          className="submit-button bg-blue-500 text-white px-4 py-2 mt-4 rounded-md"
        >
          Upload Photos
        </button>
      </form>
    </div>
      <Footer />
    </div>
  );
};

export default VendorDetailsed;
