import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../Navbaar&Footer/Navbar';
import Footer from '../Navbaar&Footer/Footer';
import axios from 'axios';
import { Link } from 'react-router-dom';

const MarketPage = () => {
  const { marketId } = useParams(); // Get the marketId from the URL
  const mapRef = useRef(null);
  const [market, setMarket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMarket = async () => {
      try {
        const response = await axios.get(`https://procydia.onrender.com/api/markets/${marketId}`);
        setMarket(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching market data:", err);
        setError("Failed to fetch market data.");
        setLoading(false);
      }
    };

    fetchMarket();

    const script = document.createElement('script');
    script.src = "https://apis.mappls.com/advancedmaps/api/080798ab65ac3d686ffa9f64ea4c511a/map_sdk?layer=vector&v=3.0&callback=initMap";
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);

    window.initMap = () => {
      if (market) {
        const [lat, lng] = market.center;
        const map = new window.mappls.Map(mapRef.current, {
          center: [lat, lng],
          zoom: 16
        });

        // Add a marker for the market center
        new window.mappls.Marker({
          position: [lat, lng],
          map: map,
          title: market.name
        });

        // Optionally, add markers for each vendor
        market.vendors.forEach(vendor => {
          if (vendor.latitude && vendor.longitude) {
            new window.mappls.Marker({
              position: [parseFloat(vendor.latitude), parseFloat(vendor.longitude)],
              map: map,
              title: vendor.companyName
            });
          }
        });
      }
    };

    return () => {
      document.body.removeChild(script);
      delete window.initMap;
    };
  }, [marketId, market]);

  if (loading) {
    return (
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <div className="flex-grow flex items-center justify-center">
          <p className="text-gray-600">Loading market data...</p>
        </div>
        <Footer />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <div className="flex-grow flex items-center justify-center">
          <p className="text-red-500">{error}</p>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="bg-gray-50 min-h-screen">
      <Navbar />
      <div className="flex flex-col lg:flex-row h-auto lg:h-screen p-4 lg:p-8">
        {/* Left side: Vendor list */}
        <div className="lg:w-2/3 w-full p-6 bg-white shadow-md rounded-lg overflow-y-auto">
          <h2 className="text-2xl font-bold mb-6 text-gray-800">{market.name} - List of Vendors</h2>
          
          {/* Show loading state */}
          {/* (Handled above, so no need here) */}
          
          {/* Display vendors */}
          <div className="grid grid-cols-1 gap-6">
            {market.vendors.map((vendor, index) => (
              <div key={vendor._id} className="bg-white shadow-lg rounded-lg p-6 flex items-center space-x-6">
                <img src={vendor.profilepicture} alt={vendor.ownerName} className="w-20 h-20 rounded-full object-cover shadow-md" />
                <div className="flex-grow">
                  <h3 className="text-xl font-semibold text-gray-900">{vendor.companyName}</h3>
                  <h4 className="text-lg text-gray-700">{vendor.productSelection}</h4>
                  <p className="text-sm text-gray-500">{vendor.address}</p>
                  <p className="text-sm text-gray-500">Contact: +91 {vendor.phone}</p>
                </div>
                <div className="flex space-x-3">
                  <Link to={`/profile/${vendor._id}`} className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300">
                    View Profile
                  </Link>
                  <a href={`https://maps.google.com/?q=${encodeURIComponent(vendor.address)}`} className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-300" target="_blank" rel="noopener noreferrer">
                    Get Directions
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Right side: Map */}
        <div className="lg:w-1/3 w-full h-96 lg:h-full mt-6 lg:mt-0 lg:ml-6">
          <div id="map" ref={mapRef} className="w-full h-full rounded-lg shadow-inner"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MarketPage;
