import React, { useEffect, useRef,useState } from 'react';
import Navbar from '../Navbaar&Footer/Navbar';
import Footer from '../Navbaar&Footer/Footer';
import axios from 'axios'; // Import axios for API requests

const vendors = [
  {
    name: 'Xperia',
    address: '123 Street',
    photo: 'vendor1.jpg',
    contact: '123-456-7890',
  },
  {
    name: 'RK Clothes',
    address: '456 Avenue',
    photo: 'vendor2.jpg',
    contact: '987-654-3210',
  },
  {
    name: 'Buger King',
    address: '789 Boulevard',
    photo: 'vendor3.avif',
    contact: '555-555-5555',
  },
  // Add more vendors as needed
];

const LMapComponent = () => {
  const mapRef = useRef(null);
  const [vendors, setVendors] = useState([]); // State to hold vendor data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await axios.get('https://procydia.onrender.com/api/vendor/lajpatmarket/vendors'); // Replace with your API endpoint
        setVendors(response.data); // Set the fetched data to state
        setLoading(false); // Set loading to false
      } catch (err) {
        console.error("Error fetching vendors:", err);
        setError("Failed to fetch vendor data."); // Set error message
        setLoading(false); // Set loading to false
      }
    };

    fetchVendors(); // Call the fetch function

    const script = document.createElement('script');
    script.src = "https://apis.mappls.com/advancedmaps/api/080798ab65ac3d686ffa9f64ea4c511a/map_sdk?layer=vector&v=3.0&callback=initMap1";
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);

    window.initMap1 = () => {
      const map = new window.mappls.Map(mapRef.current, {
        center: [28.57027265908511, 77.24123139471774],
        zoom: 16
      });

      const marker = new window.mappls.Marker({
        position: [28.57027265908511, 77.24123139471774],
        map: map
      });
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <Navbar />
      <div className="flex flex-col lg:flex-row h-screen m-10">
        {/* Left side: Vendor list */}
        <div className="lg:w-2/3 w-full p-4 lg:p-8 overflow-y-auto">
          <h2 className="text-xl font-bold mb-4">List of Vendors</h2>
          <div className="grid grid-cols-1 gap-4">
            {vendors.map((vendor, index) => (
              <div key={index} className="bg-white shadow-md rounded-lg p-4 flex items-center">
                <img src={vendor.profilepicture} alt={vendor.ownerName} className="w-16 h-16 rounded-full mr-4" />
                <div className="flex-grow">
                  <h3 className="text-lg font-semibold">{vendor.ownerName}</h3>
                  <p className="text-sm text-gray-600">{vendor.address}</p>
                  <p className="text-sm text-gray-600">Contact: {vendor.phone}</p>
                </div>
                <button className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition">
                  View Profile
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Right side: Map */}
        <div className="lg:w-1/3 w-full h-96 lg:h-full">
          <div id="map" ref={mapRef} className="w-full h-full"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LMapComponent;
