import React from "react";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";

const About = () => {
  return (
<div className="bg-gray-50 min-h-screen">
  <Navbar />
  <div className="max-w-6xl mx-auto bg-white rounded-lg shadow-2xl p-12 mt-20 mb-24">
    <h1 className="font-bold text-center text-5xl mb-12 text-gray-900">
      About Us
    </h1>

    <div className="text-lg text-gray-700 leading-relaxed space-y-8">
      <p className="text-center">
        Procydia is an organization dedicated to driving the growth of
        India's startup ecosystem. We provide best-in-class digital and
        business services for businesses at all scales. Whether you want to
        start a business or expand an existing one, Procydia is the right
        destination for you.
      </p>
      <p className="text-center">
        Our mission is to empower entrepreneurs and businesses of all sizes
        by offering top-tier services tailored to their unique needs. With a
        commitment to innovation, excellence, and customer satisfaction, we
        provide the tools, resources, and expertise necessary to turn your
        business vision into reality. Choose Procydia, where your business
        aspirations meet unparalleled support and growth opportunities.
      </p>
    </div>

    <div className="text-center text-lg text-gray-700 leading-relaxed space-y-8 mt-12">
      <p>
        Procydia is your trusted partner on the path to success. Whether you're
        looking to launch a new venture or scale an existing enterprise, we are
        committed to providing unparalleled support and growth opportunities.
      </p>
      <p>
        Our innovative approach and dedication to excellence ensure that your
        business not only survives but thrives in today's competitive landscape.
      </p>
      <p>
      📺 Check out our latest videos on YouTube, where we share insightful and valuable content to help you stay informed and inspired!
      </p>
    </div>

    {/* Embedded YouTube Video */}
    <div className="mt-5 flex justify-center">
    <div className="w-full sm:w-3/4 lg:w-1/2 aspect-video shadow-lg rounded-lg overflow-hidden">
  <iframe
    src="https://www.youtube.com/embed/C8dHvUnAz0o?si=yLG-QPduB0tdfZdA"
    title="YouTube video player"
    className="w-full h-full"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    referrerPolicy="strict-origin-when-cross-origin"
    allowFullScreen
  ></iframe>
</div>

    </div>

    {/* Call to Action Buttons */}
    <div className="flex flex-col sm:flex-row justify-center items-center gap-8 mt-16">
      <a
        href="/contact"
        className="bg-red-600 text-white text-lg font-semibold px-8 py-4 rounded-full shadow-lg hover:bg-red-700 transition duration-300"
      >
        Contact Us Now!
      </a>
      <a
        href="tel:9818347406"
        className="bg-blue-600 text-white text-lg font-semibold px-8 py-4 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
      >
        Call Us Now!
      </a>
    </div>
  </div>
  <Footer />
</div>


  );
};

export default About;
