// utils/conversionTracker.js
import { gtag } from 'ga-gtag';
export const trackConversion = (url) => {
    const callback = function () {
      if (typeof(url) !== 'undefined') {
        window.location = url;
      }
    };
  
    gtag('event', 'conversion', {
      send_to: 'AW-16732394783/2BOYCI-Ul9sZEJ-q0Ko-',
      value: 1.0,
      currency: 'INR',
      event_callback: callback
    });
  };
  