import React, { useState } from 'react';
import axios from 'axios';
import { Navigate, useParams } from "react-router-dom";


const PhotoUploadGallery = () => {
  const { vendorId } = useParams();
  const [selectedImages, setSelectedImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [error, setError] = useState("");

  // Handle image selection
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 5) {
      setError("You can only upload up to 5 images.");
      return;
    }
    
    // Preview selected images
    const imagePreviews = files.map((file) => URL.createObjectURL(file));
    setPreviewImages(imagePreviews);
    setSelectedImages(files);
    setError("");
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedImages.length === 0) {
      setError("Please select at least one image.");
      return;
    }

    // Create form data to send to API
    const formData = new FormData();
    selectedImages.forEach((image) => {
      formData.append('photos', image);
    });

    try {
      const response = await axios.put(`https://procydia.onrender.com/api/vendor/${vendorId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log("Images uploaded successfully", response.data);
      // You can add success popup/redirect as per your requirement
    } catch (error) {
      console.error("Error uploading images", error);
    }
  };

  return (
    <div className="photo-upload-gallery p-20 pt-5 ">
      <h2 className='text-2xl font-semibold mb-4'>Upload Media</h2>
      <form onSubmit={handleSubmit}>
        <input 
          type="file" 
          accept="image/*" 
          multiple 
          onChange={handleImageChange} 
          className="mb-4" 
        />
        {error && <p className="error-message text-red-500">{error}</p>}
        
        <div className="image-preview-grid grid grid-cols-3 gap-4">
          {previewImages.map((image, index) => (
            <div key={index} className="image-preview">
              <img 
                src={image} 
                alt={`Preview ${index + 1}`} 
                className="w-full h-32 object-cover" 
              />
            </div>
          ))}
        </div>

        <button 
          type="submit" 
          className="submit-button bg-blue-500 text-white px-4 py-2 mt-4 rounded-md"
        >
          Upload Photos
        </button>
      </form>
    </div>
  );
};

export default PhotoUploadGallery;
