import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { useParams } from "react-router-dom";
import axios from "axios";

const Navbar = () => {
  const { vendorId } = useParams();
  const [scrolled, setScrolled] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [servicesDropdown, setServicesDropdown] = useState(false);
  const [subServiceDropdown, setSubServiceDropdown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleScroll = () => {
    const offset = window.scrollY;
    setScrolled(offset > 50);
  };

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const toggleServicesDropdown = () => {
    setServicesDropdown(!servicesDropdown);
  };

  const toggleSubServiceDropdown = () => {
    setSubServiceDropdown(!subServiceDropdown);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    setIsLoggedIn(!!token);
  }, [location]);

  const handleLogout = async () => {
    try {
      await fetch("/api/vendors/logout", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          "Content-Type": "application/json",
        },
      });

      localStorage.removeItem("authToken");
      setIsLoggedIn(false);
      navigate("/");
    } catch (err) {
      console.error("Logout failed:", err);
    }
  };
  return (
    <nav
      className={`bg-black p-4 pl-20 pr-20 flex justify-between items-center text-white fixed top-0 left-0 w-full z-50 shadow-md ${
        scrolled ? "bg-opacity-50 shadow-2xl" : "bg-opacity-100"
      }`}
    >
      <div className="flex items-center space-x-4">
      <Link to="/">
      
     <h1 className="text-3xl font-semibold">Procydia  </h1>
</Link>

      </div>
      <div className="md:hidden">
        <button onClick={toggleNav}>
          {navOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
      </div>
      <div
        className={`flex-col md:flex-row md:flex ${
          navOpen ? "flex" : "hidden"
        } items-center space-y-4 md:space-y-0 md:space-x-6 font-semibold`}
      >
        <ul className="flex-col md:flex-row md:flex space-y-4 md:space-y-0 md:space-x-6 text-white">
          {/* <li>
            <Link
              to="/"
              className="hover:font-bold"
              onClick={() => setNavOpen(true)}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="/"
              className="hover:font-bold"
              onClick={() => setNavOpen(false)}
            >
              Products
            </Link>
          </li> */}
          <li className="relative group">
            <button
              className="hover:font-bold hover:text-gray-300 transition duration-300 ease-in-out"
              onClick={toggleServicesDropdown}
            >
              Services
            </button>
            {servicesDropdown && (
              <ul className="absolute left-0 top-full bg-gray-800 rounded-lg shadow-lg mt-2 py-2 space-y-2 w-48 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                <li className="px-4 py-2 hover:bg-gray-700 rounded-md transition duration-300 ease-in-out">
                  <Link
                    to="/weddingvendors"
                    className="hover:font-bold hover:text-gray-300"
                  >
                    Event Managment
                  </Link>
                </li>
                <li className="px-4 py-2 hover:bg-gray-700 rounded-md transition duration-300 ease-in-out">
                  <Link
                    to="/constructionvendors"
                    className="hover:font-bold hover:text-gray-300"
                  >
                    Construction
                  </Link>
                </li>
                {/* <li className="relative group">
        <button
          className="px-4 py-2 hover:bg-gray-700 rounded-md w-full text-left transition duration-300 ease-in-out"
          onClick={toggleSubServiceDropdown}
        >
          Construction
        </button>
        {subServiceDropdown && (
          <ul className="absolute left-full top-0 bg-gray-800 rounded-lg shadow-lg mt-2 py-2 space-y-2 w-48 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
            <li className="px-4 py-2 hover:bg-gray-700 rounded-md transition duration-300 ease-in-out">
              <Link to="" className="hover:font-bold hover:text-gray-300">
                Home Construction
              </Link>
            </li>
            <li className="px-4 py-2 hover:bg-gray-700 rounded-md transition duration-300 ease-in-out">
              <Link to="/constructionvendors" className="hover:font-bold hover:text-gray-300">
                Apartment Construction
              </Link>
            </li>
          </ul>
        )}
      </li> */}

                <li className="px-4 py-2 hover:bg-gray-700 rounded-md transition duration-300 ease-in-out">
                  <Link
                    to="/buisnessstartup"
                    className="hover:font-bold hover:text-gray-300"
                  >
                    Buisness & Startup
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <Link
              to="/blogs"
              className="hover:font-bold"
              onClick={() => setNavOpen(false)}
            >
              Blogs
            </Link>
          </li>
          <li>
            <Link
              to="/gallery"
              className="hover:font-bold"
              onClick={() => setNavOpen(false)}
            >
              Gallery
            </Link>
          </li>
          <li className=" group">
            <button
              className="hover:font-bold hover:text-gray-300 transition duration-300 ease-in-out"
              onClick={toggleServicesDropdown}
            >
              Contact Us
            </button>
            {servicesDropdown && (
              <ul className="absolute right-0 top-full bg-gray-800 rounded-lg shadow-lg mt-2 py-2 space-y-2 w-48 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                <li className="px-4 py-2 hover:bg-gray-700 rounded-md transition duration-300 ease-in-out">
                  <Link
                    to="tel:+919818347406"
                    className="hover:font-bold hover:text-gray-300"
                  >
                    📞: +91 9818347406
                  </Link>
                </li>
                <li className="px-4 py-2 hover:bg-gray-700 rounded-md transition duration-300 ease-in-out">
                  <Link
                    to="/contact"
                    className="hover:font-bold hover:text-gray-300"
                  >
                    Reach Out 
                  </Link>
                </li>
                {/* <li className="relative group">
        <button
          className="px-4 py-2 hover:bg-gray-700 rounded-md w-full text-left transition duration-300 ease-in-out"
          onClick={toggleSubServiceDropdown}
        >
          Construction
        </button>
        {subServiceDropdown && (
          <ul className="absolute left-full top-0 bg-gray-800 rounded-lg shadow-lg mt-2 py-2 space-y-2 w-48 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
            <li className="px-4 py-2 hover:bg-gray-700 rounded-md transition duration-300 ease-in-out">
              <Link to="" className="hover:font-bold hover:text-gray-300">
                Home Construction
              </Link>
            </li>
            <li className="px-4 py-2 hover:bg-gray-700 rounded-md transition duration-300 ease-in-out">
              <Link to="/constructionvendors" className="hover:font-bold hover:text-gray-300">
                Apartment Construction
              </Link>
            </li>
          </ul>
        )}
      </li> */}
              </ul>
            )}
          </li>  
          {/* <li className="gap-4">
            <Link
              to="/contact"
              className="hover:font-bold"
              onClick={() => setNavOpen(false)}
            >
              Contact Us
            </Link>
          </li> */}
      
          {/* <li>    
          <Link
  to="tel:+919818347406"
  className="hover:font-bold"
  onClick={() => setNavOpen(false)}
>
  📞 Call Us Now: +91 9818347406
</Link>
            </li> */}
        </ul>
        {/* {!isLoggedIn && (
          <div className="flex space-x-4">
            <Link to="/login">
              <button
                className="hover:bg-white hover:text-black text-white font-bold py-2 px-4 rounded-xl border border-white"
                onClick={() => setNavOpen(false)}
              >
                Log In
              </button>
            </Link>
            <Link to="/register">
              <button
                className="hover:bg-white hover:text-black text-white font-bold py-2 px-4 rounded-xl border border-white"
                onClick={() => setNavOpen(false)}
              >
                Sign Up
              </button>
            </Link>
          </div>
        )}*/}
        {isLoggedIn && (
          <div className="flex space-x-2">
            <Link to={`/profile/${vendorId}`}>
              <button
                className="hover:bg-white hover:text-black text-white font-bold py-2 px-4 rounded-xl"
              >
                View Profile
              </button>
            </Link>
            <Link to={`/editprofile/${vendorId}`}>
              <button
                className="hover:bg-white hover:text-black text-white font-bold py-2 px-4 rounded-xl"
              >
                Edit Profile
              </button>
            </Link>
            <button
              className="hover:bg-white hover:text-black text-white font-bold py-2 px-4 rounded-xl border border-white"
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        )} 
      </div>
    </nav>
  );
};

export default Navbar;
